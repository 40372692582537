import React, { useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Button, TextField } from '@material-ui/core';
import * as Icon from '../Icon';
import { MessageProps } from '@chatscope/chat-ui-kit-react';
import { Texts } from '../../constants';
import useStyles from './styles';
import MessageList from '../MessageList';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

interface WidgetDialogProps {
  isOpened: boolean
  messages: MessageProps[]
  isLoading: boolean
  onMessageSend: (message: string, contextAction?: string) => void
  onMessageRegenerate: (index: number) => void
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void
  onNewChat: (event: React.MouseEvent<HTMLButtonElement>) => void
  dialogPosition?: DOMRect
  isContextActionsAvailable?: boolean
}

const WidgetDialog: React.FC<WidgetDialogProps> = ({
  isOpened,
  messages,
  isLoading,
  onMessageSend,
  onMessageRegenerate,
  onClose,
  onNewChat,
  dialogPosition,
  isContextActionsAvailable,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles({ widgetPosition: dialogPosition ?? {} });
  const [isChatInputAvailable, setIsChatInputAvailable] = useState(!isContextActionsAvailable);

  const handleSendMessage = () => {
    if (!inputRef.current?.value) {
      return;
    }

    onMessageSend(inputRef.current.value)

    inputRef.current.value = ''
  }

  const handleSummarizeAction = () => {
    setIsChatInputAvailable(true);
    onMessageSend('Please, summarize the document', 'summarization')
  }

  const handleChatAction = () => {
    setIsChatInputAvailable(true);
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  }

  return (
    <Dialog
      disableEnforceFocus
      disableScrollLock
      hideBackdrop
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      scroll="paper"
      open={isOpened}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item className={classes.titleLeftContainer}>
            <div>
              <Icon.Stars className={classes.marginRight} />
              <span>{Texts.dialogTitle}</span>
            </div>
            <IconButton
              className={classes.newButton}
              size="small"
              onClick={onNewChat}
            >
              <Icon.Plus className={classes.marginRight} />
              <span>{Texts.New}</span>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              size="medium"
              className={classes.closeButton}
              disableFocusRipple
              onClick={onClose}
            >
              <Icon.XMark />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <MessageList
          messages={messages}
          isLoading={isLoading}
          onMessageRegenerate={onMessageRegenerate}
        />
      </DialogContent>
      <Divider className={classes.divider} />
      {isContextActionsAvailable && !isChatInputAvailable && (
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleSummarizeAction}
            color="primary"
            variant="contained"
          >
            Summarize attachments
          </Button>
          <Button
            onClick={handleChatAction}
            color="primary"
            variant="contained"
          >
            Chat
          </Button>
        </DialogActions>
      )}
      {isChatInputAvailable && (
        <DialogActions className={classes.dialogActions}>
          <TextField
            inputRef={inputRef}
            placeholder={Texts.enterYourPrompt}
            variant="outlined"
            className={classes.inputField}
            onKeyDown={handleKeyDown}
            disabled={isLoading}
          />
          <IconButton
            className={classes.sendButton}
            onClick={handleSendMessage}
            disabled={isLoading}
          >
            <Icon.PaperPlane />
          </IconButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default WidgetDialog;
