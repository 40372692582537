import React from 'react';
import { MessageList as OriginList, Message, TypingIndicator, MessageProps } from '@chatscope/chat-ui-kit-react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import useStyles from './styles';
import { getMessageId } from '../../containers/Widget/helpers';
import { Tooltip, IconButton } from '@material-ui/core';
import * as Icon from '../Icon';
import { Texts } from '../../constants';
import copy from 'copy-to-clipboard';
import CopyButton from '../CopyButton';

interface MessageListProps {
  messages: MessageProps[]
  isLoading?: boolean
  onMessageRegenerate: (index: number) => void
}

const MessageList: React.FC<MessageListProps> = ({
  messages,
  isLoading,
  onMessageRegenerate,
}) => {
  const classes = useStyles();

  const handleCopyClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const i = Number(event.currentTarget.getAttribute('data-index'));
    const text = messages[i].model?.message;

    if (text) {
      copy(text);
    }
  }

  const handleRegenerateClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const i = Number(event.currentTarget.getAttribute('data-index'));
    onMessageRegenerate(i);
  }

  return (
    <OriginList
      className={classes.list}
      typingIndicator={isLoading && <TypingIndicator className={classes.typing} />}
    >
      {messages.map((message, i) => {
        const isIncoming = message.model?.direction === 'incoming';
        const isFirst = i === 0; // initial message

        return (
          <Message
            key={getMessageId(message) ?? i}
            className={isIncoming ? classes.incomingMessage : classes.outgoingMessage}
            {...message}
          >
            {message.type === 'text' && (
              <Message.TextContent>
                <div className={classes.textContent}>
                  <Markdown remarkPlugins={[remarkGfm]}>
                    {message.model?.message ?? ''}
                  </Markdown>
                </div>
                {isIncoming && !isFirst && (
                  <Tooltip className={classes.tooltip} title={Texts.Copy}>
                    <CopyButton
                      className={classes.messageButton}
                      data-index={i}
                      onClick={handleCopyClick}
                    />
                  </Tooltip>
                )}
                {isIncoming && !isFirst && (
                  <Tooltip className={classes.tooltip} title={Texts.Regenerate}>
                    <IconButton
                      className={classes.messageButton}
                      data-index={i}
                      onClick={handleRegenerateClick}
                      disabled={isLoading}
                    >
                      <Icon.RotateRight />
                    </IconButton>
                  </Tooltip>
                )}
              </Message.TextContent>
            )}
          </Message>
        )
      })}
    </OriginList>
  );
}

export default MessageList;
