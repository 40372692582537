import { createStyles, makeStyles } from '@material-ui/core';
import { Colors } from '../../constants';

export default makeStyles((theme) => createStyles({
  list: {
    height: '100%',
    width: '100%',

    '& .scrollbar-container': {
      padding: theme.spacing(0, 2)
    },
    '& .cs-message': {
      overflowX: 'auto',
    },
  },
  textContent: {
    whiteSpace: 'normal',
    '& > *:first-child': {
      marginTop: 0,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
    '& table': {
      border: '1px solid black',
      borderCollapse: 'collapse',
    },
    '& table th': {
      border: '1px solid black',
      borderCollapse: 'collapse',
    },
    '& table td': {
      borderRight: '1px solid black',
      borderCollapse: 'collapse',
    },
    '& ol': {
      paddingLeft: 15,
    },
    '& ul': {
      paddingLeft: 15,
    },
  },
  outgoingMessage: {
    '& .cs-message__content': {
      backgroundColor: Colors.darkBlue,
      color: Colors.white,
    },
  },
  incomingMessage: {
    '& .cs-message__content': {
      backgroundColor: Colors.lightBlue,
    },
  },
  typing: {
    left: `${theme.spacing(2)}px !important`,
  },
  tooltip: {
    fontSize: 12,
  },
  messageButton: {
    padding: theme.spacing(1),
    '& .MuiIconButton-label': {
      width: 12,
    },
  },
}));
