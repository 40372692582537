import React, { useState } from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import * as Icon from './Icon';

const CopyButton: React.FC<IconButtonProps> = (props) => {
  const [copied, setCopied] = useState(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    props?.onClick?.(event)
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <IconButton {...props} onClick={handleClick}>
      {copied ? <Icon.Check /> : <Icon.Copy />}
    </IconButton>
  );
}

export default CopyButton;
